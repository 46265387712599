import React from 'react'
import { TooltipHost, Spinner } from "@fluentui/react";
import { SpinnerSize } from '@fluentui/react/lib/Spinner';
import { useBoolean } from "@fluentui/react-hooks";
import { mergeStyles } from "@fluentui/react/lib/Styling";
import { OrchestrationEvent } from "../models/orchestrationEvent";
import { Dialog, DialogType, DialogFooter } from "@fluentui/react/lib/Dialog";
import { PrimaryButton, DefaultButton } from "@fluentui/react/lib/Button";
import { BugSyncIcon } from "@fluentui/react-icons-mdl2";
import devOpsApi, { ReplayEventRequest } from "../services/devOpsApiClient";
import { useApi, ApiParameters } from "../hooks/useApi";
import { useState, useEffect } from "react";
import { appInsights } from "../../config/telemetryConfig";
import { Environment, getEnvironment } from "../../config/environmentConfig";
import { StatusMessage, StatusMessageBar } from '../statusMessageBar';

const iconClass = mergeStyles({
    fontSize: 15,
    height: 15,
    width: 15,
    margin: "0 0px",
    padding: "0 0px",
});

export interface EventReplayButtonProps {
    Event: OrchestrationEvent;
}

const modalPropsStyles = { main: { maxWidth: 450 } };

export function EventReplayButton(props: EventReplayButtonProps) {
    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
    const [requestReplay, setRequestReplay] = useState(false);
    const [apiParameters, updateApiParameters] = useState<ApiParameters[]>([]);
    const [ready, response, isLoading, error, execute] = useApi<any>();
    const [statusMessage, setStatusMessage] = useState<StatusMessage | null>(null);

    const dialogContentProps = {
        type: DialogType.normal,
        title: "Replay event",
        subText: JSON.stringify(props.Event.data, null, 2),
        isMultiline: true,
    };
    const modalProps = {
        isBlocking: true,
        styles: modalPropsStyles,
        dragOptions: undefined,
    };

    useEffect(() => { }, [props]);

    useEffect(() => {
        if (ready) {
            execute(apiParameters);
        }
    }, [apiParameters, ready]);

    useEffect(() => {
        if (response) {
            if (requestReplay) {
                appInsights.trackEvent({
                    name: "ReplayEvent",
                    properties: {
                        EventId: props.Event.id,
                        EventData: props.Event.data,
                        Environment: Environment[getEnvironment()],
                        Response: JSON.stringify(response),
                    },
                });

                setStatusMessage({
                    type: 'success',
                    message: `Request to replay event finished: ${JSON.stringify(response) }`,
                });
            }
            setRequestReplay(false);

            
        }
    }, [response]);

    useEffect(() => {
        if (error) {
            const errorMessage = { type: error.type, message: error.message } as StatusMessage;
            if (errorMessage.message !== "") {
                setStatusMessage(errorMessage);
            }
        }
    }, [error]);

    useEffect(() => {
        if (hideDialog == true) {
            if (statusMessage) {
                setStatusMessage(null);
            }
        }
    }, [hideDialog]);

    return (
        <div>
            <TooltipHost content="Replay Event">
                <BugSyncIcon
                    aria-label="ReplayEvent"
                    className={iconClass}
                    onClick={toggleHideDialog}
                />
            </TooltipHost>

            <Dialog
                hidden={hideDialog}
                onDismiss={toggleHideDialog}
                dialogContentProps={dialogContentProps}
                modalProps={modalProps}
            >
                <DialogFooter>
                    <PrimaryButton onClick={async () => await onReplay(false)} text="Replay" />
                    <PrimaryButton onClick={async () => await onReplay(true)} text="Republish" />
                    <DefaultButton onClick={toggleHideDialog} text="Don't replay" />
                </DialogFooter>
                {isLoading &&
                    (<Spinner size={SpinnerSize.xSmall} label="Please wait..." ariaLive="assertive" labelPosition="right" />)}
                <StatusMessageBar message={statusMessage} isMultiline />
            </Dialog>
        </div>
    ); 

    function onReplay(isRepublish: boolean) {
        var eventDataText = JSON.stringify(props.Event.data);
        
        if(isRepublish) {
            console.log(`republish event: ${props.Event.id}, ${eventDataText}`);
        }
        else
        {
            console.log(`replay event: ${props.Event.id}, ${eventDataText}`);
        }
      
        setRequestReplay(true);

        var event = JSON.parse(eventDataText);

        var productId: string = "";
        if(typeof event.ProductId != 'undefined' && event.ProductId) {
            productId = event.ProductId;
        } else {
            productId = event.DocumentId;
        }

        var correlationVector: string = event.CorrelationVector;

        const request = {
            body: [{ productId: productId, correlationVector: correlationVector, isRepublish: isRepublish }],
        } as ReplayEventRequest;

        updateApiParameters([devOpsApi.getReplayEvent(request)]); 
    }
}
